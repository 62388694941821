/* General App Styles */
html, body {
  margin: 0;
  height: 100%; /* Ensures the body and HTML take up the full viewport height */
  font-family: 'Arial', sans-serif; /* Use a more modern font */
  background-color: #f9f9f9; /* Light background for better contrast */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Makes the app fill the viewport height */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 60vh;
  text-align: center;
}

/* Header, Sub-Header, and Footer Styles */
header, .sub-header, footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px; /* Increased padding for better spacing */
  text-align: center;
}

/* Header Styles */
header {
  background-color: #4a90e2; /* Primary color for header */
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

header button {
  background-color: white;
  color: #4a90e2;
  border: none;
  margin: 0 10px;
  padding: 10px 20px; /* Increased padding for buttons */
  border-radius: 5px; /* Slightly rounded corners */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

header button:hover {
  background-color: #e8f1fd; /* Light hover effect */
  transform: translateY(-2px);
}

header button:active {
  transform: translateY(0);
}

/* Sub-Header Styles */
.sub-header {
  background-color: #e8f1fd; /* Light background for sub-header */
  color: #333;
  padding: 10px 0; /* Increased padding */
  border-bottom: 2px solid #4a90e2; /* Bottom border for separation */
}

.sub-header button {
  background-color: #4a90e2;
  color: white;
  border: none;
  margin: 0 5px;
  padding: 8px 16px; /* Increased padding */
  border-radius: 5px; /* Slightly rounded corners */
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sub-header button:hover {
  background-color: #357abd; /* Darker hover effect */
}

/* Footer Styles */
footer {
  background-color: #222; /* Dark footer background */
  color: white;
  padding: 20px;
  font-size: 14px;
  text-align: center;
}

footer a {
  color: #61dafb; /* Link color */
  text-decoration: none;
  font-weight: bold;
}

footer a:hover {
  text-decoration: underline; /* Underline on hover for links */
}

/* Center the table */
.table-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin: 20px 0; /* Add some margin for spacing */
}

table {
  border-collapse: collapse; /* Collapse borders for a cleaner look */
  width: 100%; /* Full width for responsiveness */
  max-width: 600px; /* Optional: limit the maximum width */
  margin: 0 auto; /* Center the table */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

th, td {
  border: 1px solid #ccc; /* Add border to table cells */
  padding: 12px; /* Increased padding for better spacing */
  text-align: center; /* Center align text in cells */
}

th {
  background-color: #4a90e2; /* Header background color */
  color: white; /* Header text color */
}

/* Button Styles */
.calculate-button {
  background-color: #4a90e2; /* Primary button color */
  color: white;
  border: none;
  padding: 12px 20px; /* Increased padding for better touch targets */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px; /* Larger font size for better visibility */
  font-weight: bold; /* Bold text for emphasis */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
  margin: 20px 0; /* Margin for spacing */
}

.calculate-button:hover {
  background-color: #357abd; /* Darker button on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.calculate-button:active {
  transform: translateY(0); /* Reset lift effect on click */
}

/* Calculation Results Styles */
.calculation-results {
  background-color: #f2f2f2; /* Light background for results */
  border: 1px solid #ccc; /* Border for definition */
  border-radius: 5px; /* Rounded corners */
  padding: 20px; /* Padding for spacing */
  margin-top: 20px; /* Margin for spacing from other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  text-align: left; /* Left align text for better readability */
  max-width: 600px; /* Optional: limit the maximum width */
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
}

.calculation-results h3 {
  margin-top: 0; /* Remove top margin for the heading */
  color: #4a90e2; /* Color to match the theme */
}

.calculation-results pre {
  white-space: pre-wrap; /* Wrap long lines */
  word-wrap: break-word; /* Break long words */
  color: #333; /* Darker text for better readability */
}

/* Modern Input Field Styles */
input[type="number"], input[type="text"] {
  background-color: #fff; /* White background for the input fields */
  border: 2px solid #ccc; /* Subtle border */
  padding: 10px; /* Padding inside the input fields */
  font-size: 14px; /* Slightly larger font size for readability */
  border-radius: 8px; /* Rounded corners for a soft appearance */
  transition: all 0.3s ease; /* Smooth transition for hover/focus */
  width: 100%; /* Full width for input fields */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

input[type="number"]:focus, input[type="text"]:focus {
  outline: none; /* Remove the default outline */
  border-color: #4a90e2; /* Change the border color on focus */
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.6); /* Add a subtle shadow for focus effect */
}

input[type="number"]:hover, input[type="text"]:hover {
  border-color: #4a90e2; /* Change the border color on hover */
}

input[type="number"]::placeholder, input[type="text"]::placeholder {
  color: #aaa; /* Placeholder color */
  font-size: 13px; /* Smaller font size for placeholder */
  opacity: 1; /* Ensure the placeholder is always visible */
}

/* Button Styles */
button {
  background-color: #4a90e2; /* Primary button color */
  color: white;
  border: none;
  padding: 12px 20px; /* Increased padding for better touch targets */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer;
  font-size: 14px; /* Font size for better readability */
  font-weight: bold; /* Bold text for emphasis */
  transition: all 0.3s ease; /* Smooth transition for hover/focus */
  margin: 8px 0; /* Margin for spacing between buttons */
  width: auto; /* Automatic width depending on the text */
}

/* Hover effect */
button:hover {
  background-color: #357abd; /* Darker button color on hover */
  transform: translateY(-2px); /* Slight lifting effect */
}

/* Active effect */
button:active {
  transform: translateY(0); /* Reset the lifting effect when clicked */
}

/* Focus effect (optional) */
button:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.6); /* Add a subtle glow */
}

/* Specific Button Variants (for Add Pizza, Remove, and Calculate) */
button.add-pizza {
  background-color: #58D68D; /* Green for Add Pizza button */
}

button.remove-pizza {
  background-color: #E74C3C; /* Red for Remove Pizza button */
}

button.calculate {
  background-color: #F39C12;
  margin-top: 50px;
  font-size: 20px;
}

/* Hover effect for specific buttons */
button.add-pizza:hover {
  background-color: #27AE60;
}

button.remove-pizza:hover {
  background-color: #C0392B;
}

button.calculate:hover {
  background-color: #E67E22;
}

/* Active effect for specific buttons */
button.add-pizza:active {
  background-color: #27AE60;
}

button.remove-pizza:active {
  background-color: #C0392B;
}

button.calculate:active {
  background-color: #E67E22;
}

.mt-50 {
  margin-top: 50px;
}

/* Table responsiveness */
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
  margin: 20px 0; /* Add some margin for spacing */
}

table {
  border-collapse: collapse; /* Collapse borders for a cleaner look */
  width: 100%; /* Full width to utilize available space */
  max-width: 100%; /* Ensure table doesn’t exceed the screen width */
  margin: 0 auto; /* Center the table */
}

th, td {
  border: 1px solid #ccc; /* Add border to table cells */
  padding: 12px; /* Padding for better spacing */
  text-align: center; /* Center text in cells */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

th {
  background-color: #4a90e2; /* Header background color */
  color: white; /* Header text color */
}

/* Responsive styles for smaller screens (e.g., mobile, tablet) */
@media (max-width: 768px) {
  /* Stack the columns for smaller screens */
  table, thead, tbody, th, td, tr {
    display: block; /* Make the table elements block-level for stacking */
  }

  /* Hide table header rows on smaller screens */
  thead {
    position: absolute; /* Hide the header off-screen */
    top: -9999px;
    left: -9999px;
  }

  /* Convert table rows into a card-like view */
  tr {
    border: none; /* Remove borders between rows */
    margin-bottom: 10px; /* Add margin between rows */
  }

  td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left; /* Align text to the left for a card-like layout */
    background-color: #f9f9f9;
    font-size: 14px;
  }

  td:before {
    content: attr(data-label); /* Add labels to cells */
    font-weight: bold;
    color: #4a90e2; /* Color for the labels */
    text-transform: uppercase;
  }

  /* Adjust button appearance in mobile view */
  button {
    width: 100%; /* Full-width buttons for better touch targets */
    padding: 12px 0; /* Adjust padding for mobile */
  }

  /* Optional: Reduce padding in table cells for better mobile experience */
  th, td {
    padding: 8px; /* Slightly reduced padding */
  }
}

/* Calculation Results Styles */
.calculation-results {
  background-color: #ffffff; /* White background for clean and modern look */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding for spacing */
  margin-top: 20px; /* Margin from other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  text-align: left; /* Align text to the left for better readability */
  max-width: 800px; /* Limit the width for better presentation */
  margin-left: auto; /* Center align */
  margin-right: auto; /* Center align */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Heading styles inside calculation results */
.calculation-results h3 {
  color: #4a90e2; /* Matching heading color */
  font-size: 1.5rem; /* Larger font size for the title */
  font-weight: bold;
  margin-bottom: 15px; /* Space between the heading and the result content */
  text-transform: uppercase; /* Optional: Make it uppercase */
  letter-spacing: 1px; /* Subtle spacing between letters */
}

/* Option Section Styles (Option 1, Option 2) */
.option-section {
  background-color: #f1faff; /* Light background to distinguish each option */
  border-radius: 6px; /* Rounded corners for each section */
  padding: 15px; /* Padding for spacing */
  margin-bottom: 15px; /* Spacing between options */
  border-left: 5px solid #4a90e2; /* Colored left border to visually connect with the header */
}

.option-section h4 {
  color: #333; /* Dark color for section title */
  font-size: 1.2rem; /* Slightly larger font size for section titles */
  font-weight: bold;
  margin-bottom: 10px; /* Space below the section title */
}

.option-section p {
  font-size: 1rem;
  line-height: 1.5;
  color: #666; /* Lighter color for the content */
  margin: 0;
}

/* Conclusion Section Styles */
.conclusion-section {
  background-color: #e8f1fd; /* Slightly darker background for the conclusion */
  padding: 15px;
  border-radius: 6px;
  border-left: 5px solid #357abd; /* Differentiated border color */
}

.conclusion-section h5 {
  color: #333; /* Dark color for conclusion title */
  font-size: 1.2rem; /* Same as option title */
  font-weight: bold;
  margin-bottom: 10px;
}

.conclusion-section p {
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
  margin: 0;
}

/* Hover effect for calculation results card */
.calculation-results:hover {
  transform: translateY(-2px); /* Slight lifting effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}


.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

h3 {
  margin-bottom: 20px;
}

table {
  width: 100%;
  margin-bottom: 15px;
}

table th,
table td {
  padding: 10px;
  text-align: left;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .calculation-results {
    padding: 15px; /* Reduced padding on smaller screens */
    margin-top: 15px; /* Less margin on top */
    max-width: 100%; /* Full width on smaller screens */
  }

  .calculation-results h3 {
    font-size: 1.25rem; /* Slightly smaller heading on mobile */
  }

  .option-section h4,
  .conclusion-section h5 {
    font-size: 1.1rem; /* Adjust font size of section titles on mobile */
  }

  .option-section p,
  .conclusion-section p {
    font-size: 0.95rem; /* Adjust font size of content */
  }
}

@media (max-width: 480px) {
  .calculation-results h3 {
    font-size: 1.1rem; /* Even smaller heading on very small screens */
  }

  .option-section h4,
  .conclusion-section h5 {
    font-size: 1rem; /* Further reduce title font size for small screens */
  }

  .option-section p,
  .conclusion-section p {
    font-size: 0.9rem; /* Further reduce font size for small screens */
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .calculate-button {
    width: 100%; /* Full width on smaller screens */
  }

  table {
    width: 90%; /* Slightly smaller width for smaller screens */
  }

  .calculation-results {
    width: 90%; /* Slightly smaller width for smaller screens */
  }
}
